// Body
$body-bg: #ffffff;

// Colors
$primary: #d63384;
$secondary: #f7d6e6;
$success: #432874;

$white: #fbfbfb;
$black: #010101;

// Nav Links
$navbar-light-color:                rgba($white, .9);
$navbar-light-hover-color:          rgba($white, .7);
$navbar-light-active-color:         rgba($black, .9);
$navbar-light-disabled-color:       rgba($white, .3);
$navbar-light-toggler-border-color: rgba($white, .1);

//btn-close
$btn-close-color: $white;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
