// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Azeret+Mono:wght@400;700&display=swap');

// Variables
@import 'variables';

// Bootstrap All
// @import '~bootstrap/scss/bootstrap';

// Config Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// Layout & Component Bootstrap
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/offcanvas';

// // Helpers Bootstrap
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';